/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_packs_with_chunks_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'babel-polyfill';
import Vue from 'vue';
import VueResource from 'vue-resource';
import lodash from 'lodash';
import VueLodash from 'vue-lodash';
import UserTermsPopup from 'shared/components/old_design/user_terms_popup';
import * as Spinner from 'shared/old_design/loading_spinner';

Vue.use(VueResource);
Vue.use(VueLodash, lodash)
document.addEventListener('DOMContentLoaded', () => {
  Vue.component('user_terms_popup', UserTermsPopup);

  let rootElement = document.querySelector('#user_agreement_popup');

  if (rootElement) {
    Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    let data = rootElement.dataset;

    var application = new Vue({
      el: rootElement,
      data: {
        data: data,
        userOutdatedTerms: JSON.parse(data.userOutdatedTerms)
      },
      methods: {
        acceptTerms: function(accepted) {
          Spinner.ShowLoadingSpinner();
          this.$http.post(data.acceptTermsUrl, { user_term: { accepted: accepted } }).then(response => {
            this.userOutdatedTerms = response.body.outdated_terms;
            Spinner.HideLoadingSpinner();
          }, response => {
            Spinner.HideLoadingSpinner();
          });
        }
      }
    });
  };
});
