<template>
  <div :class='{"mod-open" : popupActive, "dc-modal" : true}'>
    <div class="dc-modal--overlay"></div>
    <div class="dc-modal--wrapper mod-small">
      <div class="dc-modal--container">
        <div class="dc-modal--header">
          <h2 class="mod-xsmall-indent" style="text-align: center;">
            {{title}}
          </h2>
        </div>
        <div class="dc-modal--body">
          <div class="dc-description-block" v-html='message'></div>
          <br>
          <input type="checkbox" v-model="termAccepted" />
          <label class="dc-control-label">
            {{checkbox_label}}
          </label>
        </div>
        <div class="dc-modal--footer" style="margin-top: -35px;">
          <div class="dc-btns-group mod-flex-end">
            <button class="dc-btn mod-success" :class='{ "mod-danger" : danger, }' @click="processConfirmAction" :disabled="btnIsDisabled">
              {{confirm_btn}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  module.exports = {
    props: {
      title: String,
      message: String,
      checkbox_label: String,
      confirm_btn: String,
      danger: Boolean,
      show: Boolean
    },
    data() {
      return {
        popupActive: this.show,
        termAccepted: false
      }
    },
    methods: {
      processConfirmAction() {
        this.$emit('on_confirm', this.termAccepted);
      }
    },
    watch: {
      show: function(val) {
        this.popupActive = val;
      }
    },
    computed: {
      btnIsDisabled() {
        return !this.termAccepted;
      }
    }
  }
</script>
